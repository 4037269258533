import React from 'react'
import { Route } from 'react-router'
import { ServerControllers } from '../views/ServerControllers'
import { CreateServerController } from '../views/CreateServerController'
import { ServerControllerDetails } from '../views/ServerControllerDetails'
import { Switch } from 'react-router-dom/cjs/react-router-dom.min'

const ServerControllersRoutes = () => {
  return (
    <>
      <Route
        exact
        path={[
          '/admin/server-controllers',
          '/admin/server-controllers/create',
          '/admin/server-controllers/:name',
          '/admin/server-controllers/:name/edit'
        ]}
      >
        <Switch>
          {/* ========================= Admin Routes =================================*/}
          <Route exact path={['/admin/server-controllers', '/admin/server-controllers/create']}>
            <ServerControllers />
          </Route>
          <Route
            exact
            path={['/admin/server-controllers/:name', '/admin/server-controllers/:name/edit']}
          >
            <ServerControllerDetails />
          </Route>
        </Switch>
      </Route>
    </>
  )
}

export { ServerControllersRoutes }
