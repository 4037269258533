import { createRsrcKey, getResourceName } from 'features/resources'
import { createDataSelectorHook } from 'infra/redux'
import { SelectDropDown, TextInput, Typography } from 'procyon-ui'
import React, { useEffect, useState } from 'react'
const useSlices = createDataSelectorHook(['tagPolicys', 'accountList', 'serverControllers'])

const Config = ({ onConfigChange }) => {
  const [controllerNameInput, setControllerNameInput] = useState('')
  const [selectorType, setSelectorType] = useState('Accounts')
  const [selectedAccountKeys, setSelectedAccountKeys] = useState([])
  const [labelInput, setLabelInput] = useState('appgrp')
  const [cTagInput, setCTagInput] = useState('')

  const { slices } = useSlices()

  const getLabelsOptions = () => {
    const menuItems = [{ label: 'Select an option', value: '' }]
    const [labelKey] = labelInput.split(':')

    slices.tagPolicys.forEach((tag) => {
      const labelValue = tag.Tags.Map[labelKey] || ''
      if (!labelValue) return
      const splits = labelValue.split(',')
      splits.map((s) => {
        const str = `${labelKey}:${s}`
        if (menuItems.find((e) => e.value == str)) return
        menuItems.push({
          label: str,
          value: str
        })
      })
    })

    return menuItems
  }

  useEffect(() => {
    onConfigChange({
      controllerName: controllerNameInput,
      selectedAccountKeys,
      selectorType,
      label: labelInput,
      ctag: cTagInput
    })
  }, [controllerNameInput, selectorType, labelInput, selectedAccountKeys, cTagInput])

  return (
    <div className='flex gap-4'>
      <TextInput
        sx={{ width: '300px' }}
        label='Controller Name'
        value={controllerNameInput}
        onChange={(e) => setControllerNameInput(e.target.value)}
      />
      <div>
        <Typography variant='buttonLabel-regular' className='mb-1'>
          Selector Type
        </Typography>
        <div className='flex gap-2 items-center'>
          <SelectDropDown
            sx={{ width: '146px' }}
            menuItems={[
              {
                label: 'Account',
                selected: true,
                value: 'Accounts'
              },
              {
                label: 'Labels',
                value: 'Labels'
              },
              {
                label: 'Cloud Tags',
                value: 'CTags'
              }
            ]}
            onChange={(e) => setSelectorType(e.target.value)}
            value={selectorType}
          />
          {selectorType === 'Accounts' && (
            <SelectDropDown
              multiple
              sx={{ minWidth: '146px' }}
              //@ts-ignore
              menuItems={slices.accountList.map((account) => {
                const key = createRsrcKey(account)
                return {
                  label: getResourceName(account),
                  value: key
                }
              })}
              onChange={(e) => {
                setSelectedAccountKeys(e.target.value)
              }}
              value={selectedAccountKeys}
            />
          )}
          {selectorType === 'Labels' && (
            <>
              <SelectDropDown
                sx={{ width: '150px' }}
                menuItems={TAG_KEYS}
                onChange={(e) => {
                  setLabelInput(e.target.value)
                }}
                value={labelInput.split(':')[0]}
              />
              <SelectDropDown
                sx={{ width: '150px' }}
                menuItems={getLabelsOptions()}
                onChange={(e) => {
                  if (!e.target.value) return
                  setLabelInput(e.target.value)
                }}
                value={labelInput.split(':').length > 1 ? labelInput : ''}
              />
            </>
          )}
          {selectorType === 'CTags' && (
            <>
              <TextInput
                sx={{ width: '150px', marginTop: '-22px' }}
                label='Cloud Tag Key'
                placeholder='Eg, Name'
                onChange={(e) => {
                  setCTagInput((s) => {
                    const [, value] = s.split(':')
                    return `${e.target.value}:${value || ''}`
                  })
                }}
                value={cTagInput.split(':')[0] || ''}
              />
              <TextInput
                sx={{ width: '150px', marginTop: '-22px' }}
                label='Cloud Tag Value'
                placeholder='Eg, Procyon'
                onChange={(e) => {
                  setCTagInput((s) => {
                    const [key] = s.split(':')
                    return `${key || ''}:${e.target.value}`
                  })
                }}
                value={cTagInput.split(':')[1] || ''}
              />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

const TAG_KEYS = [
  {
    label: 'App Group',
    value: 'appgrp'
  },
  {
    label: 'Proxy Group',
    value: 'proxygrp'
  },
  {
    label: 'Policy Group',
    value: 'policygrp'
  },
  {
    label: 'Server Group',
    value: 'servergrp'
  },
  {
    label: 'Read Group',
    value: 'readgrp'
  }
]

export { Config }
