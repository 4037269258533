/**
 * Returns labels and ctags of the server controller
 * @param {*} srvCtrl
 * @returns {{ labels: string[], ctags: string[] }}
 */
export const getServerControllerTags = (srvCtrl) => {
  const labelsMap = srvCtrl.Spec.Labels.Map || {}
  const ctagsMap = srvCtrl.Spec.CTags.Map || {}

  const labels = []
  const ctags = []

  for (const key in labelsMap) {
    labels.push(`${key}:${labelsMap[key]}`)
  }

  for (const key in ctagsMap) {
    ctags.push(`${key}:${labelsMap[key]}`)
  }
  return { labels, ctags }
}

/**
 * Get the map of groups with users from server controller
 *
 * `{
 *    [group name]: string[] // users array
 * }`
 *
 * @param {*} srvCtrl
 * @returns {any}
 */
export const getServerControllerGroupsMap = (srvCtrl) => {
  const map = {}
  const groupMap = srvCtrl.Spec.GroupMap.Map

  for (const key in groupMap) {
    map[key] = groupMap[key].Elems
  }

  return map
}
