import { faGear, faUser, faUsers } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCanGoBack } from 'Core/Hooks/useCanGoBack'
import { EntityInfoBar, EntityPicker, FullScreenContentModal, LoadingFeedback } from 'V2Components'
import { createRsrcKey, getResourceName, reverseRsrcKey } from 'features/resources'
import { getUserServerName } from 'features/users'
import { createDataSelectorHook } from 'infra/redux'
import { Button, Typography } from 'procyon-ui'
import React, { useState } from 'react'
import { Config } from './components/Config'
import { Header } from './components/Header'
import { enqueueNotification } from 'Utils/Helpers'
import { reduxApiClient } from 'infra'

const useSlices = createDataSelectorHook(['userList', 'groupList', 'tagPolicys', 'accountList'])

const CreateServerController = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [config, setConfig] = useState({
    controllerName: '',
    selectedAccountKeys: [],
    selectorType: '',
    label: '',
    ctag: ''
  })

  const [selectedUsers, setSelectedUsers] = useState([])
  const [selectedGroups, setSelectedGroups] = useState([])
  const [showUsersModal, setShowUsersModal] = useState(false)
  const [showGroupsModal, setShowGroupsModal] = useState(false)

  const { slices } = useSlices()

  const canGoBackFn = useCanGoBack('/admin/server-controllers')

  const handleCreate = async () => {
    try {
      setIsLoading(true)
      await handleServerControllerCreate({ config, users: selectedUsers, groups: selectedGroups })
      enqueueNotification('Server Controller successfully created!', 'info')
      //@ts-ignore
      canGoBackFn()
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div>
      <Header onCancel={canGoBackFn} onComplete={handleCreate} />
      <div className='mt-4'>
        <Config onConfigChange={setConfig} />

        <div className='flex gap-4 min-h-[500px] h-[69.1vh] mt-4 border-t pt-2'>
          <div className='w-1/2 border-r pr-4'>
            <div className='flex justify-between items-center'>
              <div>
                <Typography variant='h4-regular'>Users</Typography>
                <Typography className='mt-1' variant='caption-regular'>
                  {selectedUsers.length} Users Selected
                </Typography>
              </div>
              <Button variant='grayBlue' icon={faGear} onClick={() => setShowUsersModal(true)}>
                Configure Users
              </Button>
            </div>

            {selectedUsers.map((entity) => {
              const serverName = getUserServerName(entity)
              return (
                <EntityInfoBar
                  width='100%'
                  endButton={false}
                  key={createRsrcKey(entity)}
                  kind={entity.ObjectMeta.Kind}
                  name={getResourceName(entity)}
                  //@ts-ignore
                  caption={serverName ? `Server Name: ${serverName}` : <em>No Server Name</em>}
                />
              )
            })}

            {selectedUsers.length === 0 && (
              <div className='h-[95%] flex items-center justify-center '>
                <Typography
                  onClick={() => setShowUsersModal(true)}
                  variant='caption-regular'
                  className='!text-lg flex flex-col gap-2 items-center justify-center cursor-pointer hover:opacity-80'
                >
                  <FontAwesomeIcon icon={faUser} size='2x' />
                  Select Users
                </Typography>
              </div>
            )}
          </div>
          <div className='w-1/2'>
            <div className='flex justify-between items-center'>
              <div>
                <Typography variant='h4-regular'>Groups</Typography>
                <Typography className='mt-1' variant='caption-regular'>
                  {selectedGroups.length} Groups Selected
                </Typography>
              </div>
              <Button variant='grayBlue' icon={faGear} onClick={() => setShowGroupsModal(true)}>
                Configure Groups
              </Button>
            </div>
            <div>
              {selectedGroups.map((entity) => {
                return (
                  <EntityInfoBar
                    endButton={false}
                    key={createRsrcKey(entity)}
                    kind={entity.ObjectMeta.Kind}
                    name={getResourceName(entity)}
                    caption={`${entity.Spec.Users.ObjectRef.length} Users`}
                  />
                )
              })}
            </div>
            {selectedGroups.length === 0 && (
              <div className='h-[95%] flex items-center justify-center '>
                <Typography
                  onClick={() => setShowGroupsModal(true)}
                  variant='caption-regular'
                  className='!text-lg flex flex-col gap-2 items-center justify-center cursor-pointer hover:opacity-80'
                >
                  <FontAwesomeIcon icon={faUsers} size='2x' />
                  Select Groups
                </Typography>
              </div>
            )}
          </div>
        </div>
      </div>
      {showUsersModal && (
        <FullScreenContentModal width='auto'>
          <EntityPicker
            entities={slices.userList}
            selectedEntities={selectedUsers}
            setSelectedEntities={setSelectedUsers}
            onCancel={() => setShowUsersModal(false)}
            onContinue={() => setShowUsersModal(false)}
            title='Select Users'
          />
        </FullScreenContentModal>
      )}
      {showGroupsModal && (
        <FullScreenContentModal width='auto'>
          <EntityPicker
            entities={slices.groupList}
            selectedEntities={selectedGroups}
            setSelectedEntities={setSelectedGroups}
            onCancel={() => setShowGroupsModal(false)}
            onContinue={() => setShowGroupsModal(false)}
            title='Select Groups'
          />
        </FullScreenContentModal>
      )}
      <LoadingFeedback
        loading={isLoading}
        message='Creating Server Controller'
        caption='Please wait..'
      />
    </div>
  )
}

const handleServerControllerCreate = async ({ config, users, groups }) => {
  try {
    const { selectedAccountKeys, controllerName, label, ctag, selectorType } = config

    const payload = {
      ObjectMeta: {
        Name: controllerName
      },
      Spec: {
        Users: {
          Elems: []
        },
        Groups: {
          Elems: []
        },
        Accounts: {
          ObjectRef: []
        },
        Labels: {
          Map: {}
        },
        CTags: {
          Map: {}
        }
      }
    }

    payload.Spec.Users.Elems = users.reduce((prev, curr) => {
      const sName = getUserServerName(curr)
      if (!sName) return prev
      return [...prev, sName]
    }, [])

    payload.Spec.Groups.Elems = groups.map((g) => g.ObjectMeta.Name)

    switch (selectorType) {
      case 'Accounts':
        payload.Spec.Accounts.ObjectRef = selectedAccountKeys.map(reverseRsrcKey)
        break
      case 'Labels':
        const [labelKey, labelValue] = label.split(':')
        payload.Spec.Labels.Map[labelKey] = labelValue
        break
      case 'CTags':
        const [cTagKey, cTagValue] = ctag.split(':')
        payload.Spec.CTags.Map[cTagKey] = cTagValue
        break
    }

    await reduxApiClient('servercontrollers').create(payload)
  } catch (error) {}
}

export { CreateServerController }
